
import React from "react"
import { graphql } from "gatsby"

import UtilityLayout from "../components/utility-layout"
import Calendar from "../images/xa-calendar-light.svg"
import Logo from "../images/xa-pink-logo.svg"

import html2canvas from "html2canvas"

function CourseImageTemplate({ data }) {

  const course = data.coursesYaml

  const firstCourse = course.dates && course.dates.length > 0 ?
    course.dates
      .sort((a, b) => (a.startDate > b.startDate))
      .find(date => date.status != "sold")
    : null

  // TODO: add background
  // TODO: add target audience
  // TODO: add better slogan

  return (
    <UtilityLayout>

      <div>

        <h2 className="mb-2">OG image card</h2>
        <div id="course-og-card" className="bg-sky-900 w-[600px] h-[315px] p-5">
          <div className="flex w-[550px] justify-center px-5">
            <img src={Logo} width={200} className="py-4" />
            {firstCourse ?
              <span className="flex items-center text-white ml-10 px-1">
                <img src={Calendar} width={64} />
                <span id="t1" className="flex flex-col leading-none mt-2 gap-0">
                  <span id="t2" className="text-4xl font-bold p-0">{new Intl.DateTimeFormat('de-DE', {}).format(new Date(firstCourse.startDate))}</span>
                </span>
              </span>
              : <></>
            }
          </div>
          <div className="flex w-[450px] justify-center mt-10 ml-12">
            <p className="text-3xl font-bold text-center text-white">{course.title}</p>
          </div>
          <div className="flex w-[450px] justify-center mt-10 ml-12">
            <p className="text-2xl text-center">Online {course.duration} workshop</p>
          </div>
        </div>
        <div className="flex w-[600px] justify-end mt-2">
          <button className="btn-primary" onClick={() => exportOgImageCard(`${course.slug.replace(/^\//, '').replace(/\/$/, '').replace(/[\/]/, '-')}-og-image.png`)}>
            Capture Image
          </button>
        </div>

      </div>

    </UtilityLayout>
  )
}

async function exportOgImageCard(imageFileName) {
  // NOTE: Ugly hack of translating some elements to produce better image. 
  // Most likely a bug in html2canvas library.
  const t1 = document.getElementById("t1")
  if (t1) {
    var t1Margin = t1.style.marginTop
    t1.style.marginTop = "-16px"
  }
  const t2 = document.getElementById("t2")
  if (t2) {
    var t2Margin = t2.style.marginTop
    t2.style.marginTop = "-12px"
  }
  await new Promise(resolve => setTimeout(resolve, 1000))
  await exportAsImage("course-og-card", imageFileName)
  if (t1) {
    t1.style.marginTop = t1Margin
  }
  if (t2) {
    t2.style.marginTop = t2Margin
  }
}

async function exportAsImage(elementId, imageFileName) {
  const element = document.getElementById(elementId)
  const canvas = await html2canvas(element)
  const image = canvas.toDataURL("image/png", 3.0)
  downloadImage(image, imageFileName)
}

function downloadImage(blob, fileName) {
  const fakeLink = window.document.createElement("a")
  fakeLink.download = fileName
  fakeLink.href = blob
  document.body.appendChild(fakeLink)
  fakeLink.click()
  document.body.removeChild(fakeLink)
  fakeLink.remove()
}

export default CourseImageTemplate

export const pageQuery = graphql`
  query CourseImageDataById(
    $id: String!
  ) {
    coursesYaml(id: { eq: $id }) {
      id
      slug
      title
      top
      type
      mode
      abstract
      language
      description
      tags
      duration
      background
      ogImage
      meta {
        keywords
        title
        description
      }
      photos {
        image
        image2x
        caption
      }
      materials {
        image
        image2x
      }
      goodies {
        icon
        text
      }
      outline {
        subtitle
        modules
      }
      testimonials {
        text
        rating
        person {
          name
          title
          image
        }
      }
      dates {
        startDate
        endDate
        status
        time
      }
      prices {
        name
        oldPrice
        price
        comment1
        comment2
      }
      targetAudience
    }
  }
`
